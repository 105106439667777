.error-info-banner {
  display: flex;
  background-color: var(--danger);
  color: var(--white);
  gap: 8px;
  padding: 10px;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  white-space: pre-wrap;
}
