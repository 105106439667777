.loading-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 223.75px;
  display: flex;
  direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #ffffffb3;
  z-index: 700;
  &.centered {
    padding-top: 0;
    & .justui__icon-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  animation: spin 1s linear infinite;
  color: var(--primary);
}
