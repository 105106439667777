.demoRequestForm {
  &__container {
    gap: 10px;
    input {
      margin-top: 4px !important;
    }
  }
  &__message {
    color: var(--primary-600);
  }
  &__highlight {
    color: var(--primary-600);
    background-color: unset;
  }
  &__row {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
  }
}
