.balanceRefillModal {
  :global(.modal-body) {
    padding-top: 0;
    padding-bottom: 0;
  }
  :global(.modal-header) {
    button {
      outline: none;
    }
  }
  :global(.modal-footer) {
    justify-content: start;
    :global(.btn) {
      outline: none;
    }
    :global(.btn-secondary) {
      background-color: var(--white);
      border-color: var(--indigo-600);
      color: var(--indigo-600);
      &:hover {
        background-color: var(--indigo-600);
        color: var(--white);
      }
      &:focus {
        box-shadow: 0 0 0 2px var(--indigo-600);
        border-color: var(--white);
        background-color: var(--indigo-600);
        color: var(--white);
      }
      &:active {
        background-color: var(--indigo-700);
        color: var(--white);
      }
    }
  }
}
