@use 'scss/mixins/custom';

html,
body,
#root {
  height: 100%;
  @include custom.scrollbar;
}

body {
  background: var(--white);
  color: var(--gray-800);
}

.page-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-areas:
    'header'
    'content';

  .page-content {
    flex: 1;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    grid-area: content;
    overflow-y: auto;
    @include custom.scrollbar;
  }

  .page-content-full {
    display: flex;
    flex: 1;
    width: 100%;
    grid-area: content;
    overflow-y: auto;
    @include custom.scrollbar;
  }
}

p {
  margin-bottom: 0;
}

a {
  color: var(--indigo-600);
  text-decoration: none;
}
