@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.7vw;
    min-width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8bff6;
    border-radius: 6px;
    min-height: 60px;
    &:hover {
      background-color: transparentize(#c8bff6, 0.2);
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

@mixin scrollbar__console {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-200);
    border-radius: 6px;
    background-clip: padding-box;
    min-height: 60px;
    &:hover {
      background-color: var(--blue-100);
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

@mixin input-basic {
  background: var(--blue-100);
  border-radius: 6px;
  border: 1px solid var(--blue-200) !important;
  transition: box-shadow 0.15s, border-color 0.15s;
  &:focus {
    box-shadow: 0 0 0 2px rgba(96, 95, 227, 0.3) !important;
  }
  &:hover {
    border: 1px solid var(--blue-200) !important;
  }
  ::placeholder {
    color: var(--gray-500);
  }
}

@mixin button-basic {
  position: relative;
  border: 1px solid var(--primary);
  color: white;
  background-color: var(--primary);
  border-radius: 6px;

  &::before {
    box-sizing: content-box;
    position: absolute;
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -4px;
    left: -4px;
    border: 2px solid transparent;
    border-radius: 7px;
    transition: border-color 0.15s;
  }
  &:hover {
    background-color: var(--indigo-700) !important;
    // border: 1px solid var(--indigo-700);
  }
  &:focus {
    box-shadow: none;
    color: #f3f3f3;
    // border: 1px solid var(--indigo-700);
    &::before {
      border: 2px solid var(--indigo-500);
    }
  }
  &:active {
    &::before {
      border: 2px solid transparent;
    }
  }
}
