:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: var(--indigo-800);
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-toast-width: 'auto';
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 0px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 750;
}

.Toastify__toast {
  border-radius: 8px;
  padding: 0;
  max-width: 600px;
}

.Toastify__toast-body {
  padding: 8px 24px;
  white-space: pre-line;
}

.Toastify__toast-body > div:last-child {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
