.accessRequestForm {
  &__container {
    gap: 10px;
    input {
      margin-top: 4px !important;
    }
  }
  &__message {
    color: var(--primary-600);
  }
}
