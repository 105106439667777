.tabs-navigate {
  .justui__tab {
    display: flex;
    > .nav-link {
      padding: 0;
      > * {
        padding: 8px 16px;
        text-decoration: none;
        color: unset;
        letter-spacing: inherit;
        transition: color 0.15s;
      }
    }
  }
}
