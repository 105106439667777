.menu {
  &__list {
    z-index: 5;
    box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 6px 12px 0px #0c2b4229, 0px 0px 2px 0px #0000001a;
    border: none;

    :global(.dropdown-item) {
      outline: none;
      transition: background-color 0.15s;
      &:hover {
        background-color: var(--primary-100);
      }
      &:focus {
        background-color: var(--primary-200);
      }
      &:active {
        background-color: var(--primary-400);
      }
    }
  }
  &__toggle {
    width: 48px;
    height: 36px;
    > button {
      margin: 0;
      display: flex;
      width: 100%;
    }
  }
  &__linkItem {
    padding: 0 !important;
    > a {
      display: flex;
      color: unset;
      padding: 8px 20px;
      text-decoration: none;
    }
  }
}
