.buildInfo {
  display: flex;
  gap: 20px;
  color: var(--gray-800);
  overflow: hidden;
  margin-left: 20px;
  @media (max-width: 820px) {
    display: none;
  }
}
