.balanceRefillForm {
  :global(.modal-body) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__container {
    gap: 12px;
    input {
      margin-top: 4px !important;
    }
  }
  &__message {
    color: var(--primary-600);
  }
  &__radioButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 0;
  }
}
