.dropdown {
  &__container {
    position: relative;
    height: min-content;
    svg {
      transition: transform 0.15s;
    }
  }
  &__content {
    position: absolute;
    transform: translateY(4px);
    box-shadow: 0 0 2px rgb(0 0 0 / 10%), 0 6px 12px rgb(12 43 66 / 16%), 0 0 1px rgb(17 60 92 / 6%);
    padding: 4px 0;
    z-index: 750;
  }
  &__labelWrap {
    display: flex;
    gap: 10px;
    &-open {
      @extend .dropdown__labelWrap;
      background-color: var(--primary-100);
      color: var(--primary-700) !important;
      > * {
        color: var(--primary-700) !important;
      }
    }
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    z-index: 100;
    overflow: hidden;
  }
}
