.cmp-input {
  width: 100%;

  &__require-label {
    color: var(--red-500);
  }

  & .justui_input-text {
    margin-top: 8px;
    background: var(--blue-100);
    border-radius: 6px;
    // border: none;
    border: 1px solid var(--blue-200);
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      background: var(--white);
    }

    &::placeholder {
      color: var(--gray-500);
    }
  }

  &_error {
    &:focus-within .cmp-input__error-box {
      display: none;
    }
    &:focus-within .justui_input-text {
      border: none;
      outline: none;
    }

    .cmp-input__error-box {
      color: var(--red-500);
    }

    & .justui_input-text {
      border: 1px solid var(--red-400);
    }
  }
}
