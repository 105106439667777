.header-button {
  &:hover {
    background: var(--indigo-100) !important;
  }
  &:focus {
    border-color: transparent !important;
    color: var(--indigo) !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  &:active {
    border-color: transparent !important;
    color: var(--indigo) !important;
    box-shadow: none !important;
    background: var(--indigo-200) !important;
  }

  &.user {
    background-color: var(--gray-100);
    border-radius: 50%;
    &:focus {
      background: var(--gray-100) !important;
    }
    &:hover {
      background: var(--indigo-200) !important;
    }
    &:active {
      background: var(--indigo-300) !important;
    }
  }
}
