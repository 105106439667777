.button-cmp {
  margin: 3px;
  &.btn {
    position: relative;
    // margin: 3px;
    &::before {
      box-sizing: content-box;
      position: absolute;
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -4px;
      left: -4px;
      border: 2px solid transparent;
      border-radius: 7px;
      transition: border-color 0.15s;
    }
    &:focus {
      box-shadow: none;
      &::before {
        border: 2px solid var(--indigo-500);
      }
    }
    &:active {
      &::before {
        border: 2px solid transparent;
      }
    }
  }
  &--round {
    &.btn {
      border-radius: 50% !important;
      &::before {
        border-radius: 50% !important;
      }
    }
  }

  &.btn-primary {
    border: 1px solid var(--primary);
    &:hover {
      background-color: var(--indigo-700);
      border: 1px solid var(--indigo-700);
    }
    &:focus {
      box-shadow: none;
      color: #f3f3f3;
      border: 1px solid var(--indigo-700);
    }
  }

  &.btn-outline-primary {
    &:focus {
      color: var(--indigo-600);
      border: 1px solid var(--indigo-600);
      box-shadow: none;
      background-color: var(--white);
    }
  }
  &.disabled {
    background-color: var(--indigo-300);
    color: var(--white);
  }

  &.btn-flat {
    border-color: transparent;
    &::before {
      display: none;
    }
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
  &--ghost {
    background-color: transparent;
    border: 1px solid var(--blue-200);
    color: var(--dark-gray-800);
    &:hover {
      background-color: var(--indigo-100);
    }
    &:active {
      background-color: var(--indigo-200);
    }
  }
  &--link {
    color: var(--indigo-600);
    &.btn {
      padding: 0;
      margin: 0;
    }
    &::before {
      display: none;
    }
    &:hover {
      color: var(--indigo-700);
    }
    &:focus {
      color: var(--indigo-600);
      box-shadow: none;
    }
    &:active {
      color: var(--indigo-800);
    }
  }
  & .btn-spinner {
    svg {
      color: white;
    }
    background: transparent !important;
    min-height: 24px;
    position: relative;
  }
}
