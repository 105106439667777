@use 'scss/mixins/custom';

.cmp-textarea {
  margin: 0px;
  width: 100%;

  &:focus-visible,
  & textarea {
    outline: none !important;
  }
  & textarea {
    transition: box-shadow 0.15s, color 0.15s;
    background: var(--blue-100);
    border-radius: 6px;
    padding: 6px 50px 6px 12px;
    border-radius: 6px !important;
    border: 1px solid var(--blue-200);
    background-color: var(--blue-100);
    &:focus {
      box-shadow: 0 0 0 2px rgba(96, 95, 227, 0.3) !important;
      background-color: var(--white);
    }
    &::placeholder {
      color: var(--gray-500);
    }
  }

  &--autogrow {
    @extend .cmp-textarea;
    display: grid;

    &::after {
      content: attr(data-replicated-value) ' ';
      white-space: pre-wrap;
      visibility: hidden;
      max-height: inherit;
    }
    > textarea {
      resize: none;
      overflow: auto;
      @include custom.scrollbar;
    }
    &::after,
    > textarea {
      grid-area: 1/ 1/ 2/ 2;
      padding: 6px 50px 6px 12px;
      border-radius: 6px;
      border: 1px solid var(--blue-200);
      overflow-wrap: anywhere;
    }
  }
  &--error {
    &:focus-within + .cmp-textarea__error-box {
      display: none;
    }
    &:focus-within textarea {
      border: none;
      outline: none;
    }

    &:focus-within {
      border: unset;
    }
    textarea {
      border: 1px solid var(--red-400);
    }
  }
  &__error-box {
    padding-top: 8px;
    color: var(--red-500);
  }
}
