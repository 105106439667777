.actionMessageContainer,
.generalInfoMessageContainer {
  --toastify-color-error: var(--red-600);
  white-space: pre;
  z-index: 850;
}

.commonMessageContainer {
  z-index: 850;
}

.generalInfoMessageContainer {
  width: 100%;
}
